.lg-outer .lg-has-vimeo .lg-video-play {
  background: url("../images/vimeo-play.png") no-repeat scroll 0 0 transparent;
}

.lg-outer .lg-has-vimeo:hover .lg-video-play {
  background: url("../images/vimeo-play.png") no-repeat scroll 0 -58px transparent;
}

.lg-outer .lg-has-html5 .lg-video-play {
  background: transparent url("../images/video-play.png") no-repeat scroll 0 0;
}

.lg-outer .lg-has-youtube .lg-video-play {
  background: url("../images/youtube-play.png") no-repeat scroll 0 0 transparent;
}

.lg-outer .lg-has-youtube:hover .lg-video-play {
  background: url("../images/youtube-play.png") no-repeat scroll 0 -60px transparent;
}

.lg-outer .lg-item {
  background: url("../images/loading.gif") no-repeat scroll center center transparent;
}

.lSAction>a {
  background-image: url('../images/controls.png');
}

img {
  max-width: 100%;
  height: auto;
}

/* Utility Classes and Inital Styles
  ============================================================*/
.main-aside {
  margin-top: 20px;
  margin-bottom: 20px;
}

html.video {
  padding: 0 !important;

}

html,
body {
  font-family: "Roboto", Arial, Helvetica, sans-serif;
  background: #fff;
  margin: 0;
  padding: 0;
  font-weight: 300;
  font-size: 16px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 0 20px 0;
  font-weight: bold;
}

p {
  margin: 0 0 20px 0;
}

strong {
  background: none;
  padding: 0;
  font-weight: bold;
}

a {
  color: #428bca;
  text-decoration: none;
}

a:hover {
  color: rgb(26, 99, 162);
}

.pagination>li>a {
  color: #cf3020 !important;
}

.pagination>.active>a {
  background-color: #cf3020 !important;
  color: #fff !important;
}

.group:after {
  content: "";
  display: table;
  clear: both;
}

.clr {
  clear: both;
}

.android p {
  background: url("/wp-content/uploads/images/transparent.png") repeat scroll left top transparent;
}

.full {
  width: 100%;
}

menu,
ul,
ol {
  margin: 0;
  padding: 0;
}

header ul li>ul {
  list-style-type: none;
  position: absolute;
  display: none;
}

.btn {
  background: #cf3020;
  color: #fff;
  transition: background .3s ease-in;
  border-radius: 0;

  &:hover {
    color: #fff;
    background: #e14f41;
  }
}

.panel {
  box-shadow: none;
  margin-bottom: 0;
  background: #fff;
}

.nav>li>a:hover {
  background: none;
}

menu li span {
  display: inline-block;
  padding: 10px 15px;
}

.application-buttons img {
  display: block;
}

.frontpage aside img {
  max-width: 100%;
  height: auto;
}

.frontpage aside {
  font-family: "ff-enzo-web", sans-seif;
}

.frontpage aside ul {
  padding-left: 15px;
}

.main-aside h3 {
  color: rgb(207, 48, 32);
  font-size: 30px;
  margin-bottom: 10px;
  font-weight: 300;
}

.main-aside .user-bookmarks {
  margin-bottom: 20px;
  padding-left: 0;
  list-style-type: none;
}

.main-aside .user-bookmarks li a {
  color: #333;
  font-size: 20px;
  line-height: 1.0;
}

.main-aside .user-bookmarks li .remove-bookmark a {
  color: red;
  font-weight: 700;
  position: relative;
  left: 15px;
}

.main-aside .user-bookmarks li .remove-bookmark a:hover {
  text-decoration: none;
}

.interior-content h2 {
  font-weight: 300;
  color: rgb(207, 48, 32);
}

.main-aside-nav {
  margin: 0 0 20px 0 !important;
  list-style-type: none;
}

.main-aside-nav li a {
  display: inline-block;
  padding: 4px 0;
  color: #333;
  font-size: 22px;
  line-height: 1.0;
}

.main-aside img {
  margin-bottom: 20px;
  max-width: 100%;
}

.main-aside-nav li {
  padding-bottom: 2px;
}

.log-in-form label {
  display: block;
}

.log-in-form input[type="text"],
.log-in-form input[type="password"] {
  width: 250px;
  border: 1px solid #ccc !important;
}

.smaller-image {
  max-width: 140px;
  max-height: 105px;
}

.listing-content {
  padding-top: 20px;
}

.main-aside {
  font-family: "ff-enzo-web";
}

.backendMain {
  padding-top: 60px;
  padding-bottom: 60px;
}

.btn.admin-btn {
  margin-bottom: 20px;
}

/* HEADER
  ============================================================*/
header {
  height: 115px;
  padding-top: 15px;
  overflow: visible;
  background: #fff;
  border-top: 4px solid rgb(207, 48, 32);
  border-bottom: 1px solid #ccc;
}

header.open {
  height: auto;
}

.mobileMenu {
  height: 65px;
  text-indent: -9999px;
}

header nav {
  display: none;
}

header.open nav {
  display: block;
}

.mobileMenu a {
  display: block;
  background: url('/wp-content/uploads/images/mobile.png') right center no-repeat;
  height: 65px;
}

menu .nav li {
  float: left;
}

.nav>li>a {
  color: #333;
}

header h1 a:hover,
footer h1 a:hover,
header h1 a:focus,
footer h1 a:focus {
  text-decoration: none;
}

header .logo-big,
footer .logo-big {
  color: #d23327;
  color: rgb(210, 51, 39);
  padding-left: 70px;
  font-size: 30px;
}

header .logo-small,
footer .logo-small {
  font-size: 16px;
  color: #333;
  font-weight: normal;
  padding-left: 72px;
}

header .logo img,
footer .logo img {
  position: absolute;
}

header .logo:after {
  content: url('/wp-content/uploads/images/logo-under.png');
  position: relative;
  z-index: 300;
}

.interior header .logo:after {
  display: none;
}

header nav .menu li a {
  text-align: center;
}

header nav .menu li:last-of-type a {
  padding-right: 0;
}

header nav .menu li:last-of-type a:after {
  content: ""
}

footer nav .menu li:last-of-type a {
  padding-right: 0;
}

footer nav .menu li:last-of-type a:after {
  content: "";
}

header nav .nav li a :hover,
footer nav .menu li a:hover {
  text-decoration: underline;
}

.user-menu {
  list-style-type: none;
  margin-bottom: 20px;
}

.user-menu li a {
  color: #333;
  font-size: 22px;
  line-height: 1.0;
}

/* HERO
  ============================================================*/

.hero {
  background: whitesmoke;
  background-image: url('/wp-content/uploads/images/hero-background.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
  padding-top: 170px;
}

.hero1 h2 {
  font-size: 50px;
  text-align: center;
  font-weight: 500;
  font-family: "ff-enzo-web";
}

input[type="password"] {
  border: 1px solid #ccc;
}


.signup .theContent {
  margin-top: 20px;
}

.signup input[type="password"],
.signup input[type="text"] {
  width: 240px;
  display: block;
}

.cta-buttons {
  margin-left: auto;
  margin-right: auto;
  display: block;
  width: auto;
}

.cta-buttons .btn {
  font-size: 25px;
  font-weight: 300;
  width: 100%;
  margin-bottom: 10px;
}

/* STRAPLINE
  ============================================================*/

.strapline {
  background-color: rgba(255, 255, 255, .45);
  padding: 10px 0;
  position: absolute;
  width: 100%;
  z-index: 200;
  bottom: 0;
  color: #000;
  /* text color */
  font-family: "ff-enzo-web";
  font-size: 22px;
  border-top: 1px solid #ef3a33;
  border-bottom: 1px solid #ef3a33;
}

.strapline p {
  margin-bottom: 0;
}

/* INTERIOR PAGES
  ============================================================*/
.interior {
  background-color: #f8f8f8;
}

body .mainContent {
  padding-top: 0;
  background-color: #f8f8f8;
}

.main-aside {
  background-color: #fff;
  padding-top: 20px;
  border-top: 3px solid rgb(207, 48, 32);
}

.interior-content {
  padding: 20px 10px 10px 20px;

  @include mobile {
    padding: 100px 10px 10px 20px;
  }

  @include laptop {
    padding: 20px 10px 10px 20px;
  }
}

.about-photo {
  float: left;
  margin-right: 10px;
}

div[itemtype="http://schema.org/LocalBusiness"] {
  visibility: hidden;
  position: absolute;
}

.interior footer {
  border-top: 1px solid rgb(234, 178, 173);
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, .5);
}

/* BLOG
  ============================================================*/

.frontBlog h3 {
  margin-bottom: 0;
}

.frontBlog h3 a {
  font-weight: 400;
  font-size: 24px;
}

.frontBlog p a {
  text-decoration: underline;
}

time {
  color: #ccc;
  font-size: 14px;
  font-style: italic;
  display: block;
  margin-bottom: 20px;
  border-bottom: 1px solid #ccc;
}

.frontBlog h2 {
  font-size: 22px !important;
  margin-bottom: 10px;
}

.respon1>article {
  margin-bottom: 40px;
}

.frontBlog h2.header {
  font-size: 40px !important;
  margin-bottom: 40px;
}

.frontBlog img {
  display: none;
}

.blogMain {
  margin-top: 65px;
  background: #fcfcfc;
}

.blog article h2 {
  margin-bottom: 0;
}

.blog article h2 a {
  color: #333;
  font-size: 30px;
  margin-bottom: 0;
}

.blogMain article {
  background: #fff;
  border: 1px solid #ccc;
  padding: 10px;
  margin-bottom: 20px;
}

.blogMain aside ul {
  list-style-type: none;
  margin: 0 0 20px 0;
  padding: 0;
}

.blogMain aside ul li {
  border-bottom: 1px solid #ccc;
}

.blogMain aside ul li a {
  color: #333;
  display: block;
  padding: 10px;
}

.blogMain aside ul li a:hover {
  color: #336699;
  background: #f0f0f0;
  text-decoration: none;
}

.blogTag li {
  border: 1px solid #ccc;
  background: #fff;
  padding: 0px;
  border-radius: 4px;
  float: left;
  margin-right: 4px;
  margin-bottom: 4px;
}

.blogTag a {
  padding: 5px;
  line-height: 1;
}

/* FRONTPAGE PROPERTY LISTINGS
  ============================================================*/

.frontMain {
  background-color: #f8f8f8;
  padding-top: 30px;
  padding-bottom: 30px;
}

.panel {
  border: none;
}

.frontpage .theContent {
  margin-top: 20px;
}

.front-listing {
  border: 1px solid #ccc;
  border-bottom: 5px solid #cf3020;
  border-radius: 5px 5px 5px 5px;
  margin-bottom: 40px;
}

.front-listing-top,
.top-red-part {
  background-color: #cf3020;
  background-color: rgb(207, 48, 32);
  color: white;
  padding-left: 10px;
  border-radius: 5px 5px 0 0;
  min-height: 38px;
}

.front-listing-main {
  padding-left: 0;
  padding-right: 10px;
  padding-left: 10px;
  background: #fff;

  .btn-primary {
    margin-bottom: 10px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

.single-estate_property section.panel.mainContent {
  padding-top: 80px;
}

.frontpage .front-listing-main {
  padding-left: 0;
}

.front-listing-main>p:first-of-type {
  padding-top: 10px;
}

.front-listing-top,
.property-list-heading {
  position: relative;

  .property-title {
    display: inline-block;
    position: absolute;
    width: 100%;
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 20px;

    a {
      display: block;
      width: 100%;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      color: inherit;
    }
  }

  .property-address {
    display: block;
    position: absolute;
    top: 0;
    right: 100%;
    bottom: 0;
    padding: 8px 8px 0 10px;
    background-color: #cf3020;
    white-space: nowrap;
    font-size: 15px;

    @include laptop {
      display: inline;
      padding: 8px 8px 0 20px;
    }
  }

  .property-price {
    position: absolute;
    right: 0;
    background-color: black;
    padding-right: 20px;
    border-radius: 0 5px 0 0;
    padding-bottom: 5px;
    padding-top: 5px;
    padding-left: 20px;
    font-size: 20px;
    font-weight: 600;
    display: none;
  }
}

.main-prop-data {
  float: left;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
  position: relative;
  text-align: center;
}

.main-prop-data p {
  text-align: left;
}

.main-prop-data a.btn.btn-primary,
.main-prop-data a.btn.btn-primary:hover {
  background: #cf3020;
  border: #cf3020;
  width: 100%;
}

.vidOverlay {
  display: block;
  position: absolute;
  text-indent: -9999px;
  width: 100%;
  height: 100%;
  background: url('/wp-content/uploads/images/play.png') center center rgba(0, 0, 0, .3) no-repeat;
  top: 10px;
  left: 10px;
}

.main-prop-data p {
  padding-top: 10px;
}

.main-prop-data img {
  width: 100%;
}

/* INTERIOR PROPERTY LISTINGS
  ============================================================*/

.property-listing {
  border: 1px solid #ccc;
  border-radius: 10px 10px 0 0;
}

.property-listing .property-listing-top {
  border-radius: 5px 5px 0 0;
}

.property-listing .property-list-heading {
  color: white;
  background-color: rgb(207, 48, 32);
  border-radius: 10px 10px 0 0;
}

.property-list-heading .property-title {
  padding-top: 5px;
  display: block;
  padding-left: 10px;
  font-size: 18px;
}

.property-list-heading .property-price {
  color: white;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 20px;
  display: block;
  background: black;
}

.property-list-heading .property-address {
  display: block;
  padding-right: 10px;
  font-size: 15px;
  padding-left: 10px;
}

.property-listing .listing-main {
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: white;
}

.property-listing .left-part {
  float: left;
}

.listing-container-bottom img {
  max-width: 100%;
  height: auto;
  padding-bottom: 5px;
  width: 49%;
  max-height: 10000px;
}

/* Property listing tabs at the bottom */

.property-listing-tabs {
  margin-top: 20px;
}

.property-listing-tabs li.active a {
  background-color: #eee;
  color: #333;
}

.property-listing-tabs li.active a:hover {
  background-color: #eee;
  color: #333;
}

.property-listing-tabs li a {
  background-color: #fff;
  color: #333;
  border: 1px solid #ccc;
}

.property-listing-tabs li a:hover {
  background-color: #ccc;
  color: white;
  border-color: transparent;
}

.property-listing-tabs .tab-content {
  border: 1px solid #ccc;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
  background-color: white;
}

#details {
  margin-bottom: 20px;
}

.property-amenities {
  padding-left: 20px;
}



.nav-tabs>li {
  width: 50%;
}

.property-amenities li:empty {
  display: none;
}

.main-media-container img {
  max-width: 100%;
  border: 1px solid #ccc;
}

.showing-form .btn {
  margin-top: 20px;
}

#gallery img {
  max-width: 100%;
  height: auto;
  padding-bottom: 5px;
}

.front-listing-property-data {
  float: right;
  background-color: rgb(234, 234, 234);
  margin-top: 10px;
  padding: 10px;
}

.front-listing-property-data ul {
  list-style-type: none;
}

.listing-main .group {
  padding: 6px 0;
  border-bottom: 1px solid #ccc;
}

.listing-main .pull-left {
  font-weight: bold;
}

.listing-main .tab-content {
  text-align: center;
}

/* APPLICATION FORMS
  ============================================================*/
.application .application-block {
  margin-bottom: 20px;
}

.from-group {
  margin-bottom: 10px;
}

.application-block p {
  margin-bottom: 10px;
}

.application .form-group {
  content: "";
  display: table;
  clear: both;
  width: 100%;
}

.application .btn {
  font-size: 20px;
}

.app-100,
.app-70,
.app-80,
.app-60,
.app-50,
.app-40,
.app-30,
.app-20,
.app-25,
.app-23,
.app-16 {
  float: left;
  font-weight: bold;
}

input.app-100,
input.app-70,
input.app-80,
input.app-60,
input.app-50,
input.app-40,
input.app-30,
input.app-25,
input.app-20,
input.app-23,
input.app-16 {
  font-weight: normal;
}

.app-100 {
  width: 100% !important;
}

.app-70 {
  width: 70% !important;
}

.app-60 {
  width: 60% !important;
}

.app-23 {
  width: 23% !important;
}

.app-50 {
  width: 50% !important;
}

.app-40 {
  width: 40% !important;
}

.app-30 {
  width: 30% !important;
}

.app-25 {
  width: 25% !important;
}

.app-20 {
  width: 20% !important;
}

.app-16 {
  width: 16.6666667% !important;
}

/* CONTACT
  ============================================================*/

.form-block {
  margin-bottom: 22px;
}

.formControlLabel {
  color: #336699;
}

input[type="text"],
textarea {
  width: 100%;
  border: 1px solid #ccc;
  padding: 3px 10px;
}

textarea {
  height: 200px;
}

.googleMap,
#map-canvas {
  height: 400px;
}

/* FOOTER
  ============================================================*/

footer.panel {
  background-color: rgb(238, 238, 238);
  padding: 15px 0;
}


/* COPYRIGHT
  ============================================================*/

.copyright {
  font-size: 12px;
  background-color: rgb(46, 46, 46);
  color: white;
  padding-top: 20px;
  padding-bottom: 20px;
}

.copyright a {
  color: white;
}

.copyright a:hover {
  color: white;
}

.linksCopy,
.clientCopy {
  text-align: center;
}

header nav .menu li a {
  width: 100%;
}

header nav .menu li {
  width: 100%;
}

/* LIGHTBOX CSS
  ============================================================*/

/* Preload images */
body:after {
  display: none;
}

.lightboxOverlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;
  background-color: black;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
  opacity: 0.8;
  display: none;
}

.lightbox {
  position: absolute;
  left: 0;
  width: 100%;
  z-index: 10000;
  text-align: center;
  line-height: 0;
  font-weight: normal;
}

.lightbox .lb-image {
  display: block;
  height: auto;
  max-width: inherit;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  border-radius: 3px;
}

.lightbox a img {
  border: none;
}

.lb-outerContainer {
  position: relative;
  background-color: white;
  *zoom: 1;
  width: 250px;
  height: 250px;
  margin: 0 auto;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;
}

.lb-outerContainer:after {
  content: "";
  display: table;
  clear: both;
}

.lb-container {
  padding: 4px;
}

.lb-loader {
  position: absolute;
  top: 43%;
  left: 0;
  height: 25%;
  width: 100%;
  text-align: center;
  line-height: 0;
}

.lb-cancel {
  display: block;
  width: 32px;
  height: 32px;
  margin: 0 auto;
  background: url(../images/loading.gif) no-repeat;
}

.lb-nav {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 10;
}

.lb-container>.nav {
  left: 0;
}

.lb-nav a {
  outline: none;
  background-image: url('data:image/gif;base64,R0lGODlhAQABAPAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==');
}

.lb-prev,
.lb-next {
  height: 100%;
  cursor: pointer;
  display: block;
}

.lb-nav a.lb-prev {
  width: 34%;
  left: 0;
  float: left;
  background: url(../images/prev.png) left 48% no-repeat;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
  -webkit-transition: opacity 0.6s;
  -moz-transition: opacity 0.6s;
  -o-transition: opacity 0.6s;
  transition: opacity 0.6s;
}

.lb-nav a.lb-prev:hover {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1;
}

.lb-nav a.lb-next {
  width: 64%;
  right: 0;
  float: right;
  background: url(../images/next.png) right 48% no-repeat;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
  -webkit-transition: opacity 0.6s;
  -moz-transition: opacity 0.6s;
  -o-transition: opacity 0.6s;
  transition: opacity 0.6s;
}

.lb-nav a.lb-next:hover {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1;
}

.lb-dataContainer {
  margin: 0 auto;
  padding-top: 5px;
  *zoom: 1;
  width: 100%;
  -moz-border-radius-bottomleft: 4px;
  -webkit-border-bottom-left-radius: 4px;
  border-bottom-left-radius: 4px;
  -moz-border-radius-bottomright: 4px;
  -webkit-border-bottom-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.lb-dataContainer:after {
  content: "";
  display: table;
  clear: both;
}

.lb-data {
  padding: 0 4px;
  color: #ccc;
}

.lb-data .lb-details {
  width: 85%;
  float: left;
  text-align: left;
  line-height: 1.1em;
}

.lb-data .lb-caption {
  font-size: 13px;
  font-weight: bold;
  line-height: 1em;
}

.lb-data .lb-number {
  display: block;
  clear: left;
  padding-bottom: 1em;
  font-size: 12px;
  color: #999999;
}

.lb-data .lb-close {
  display: block;
  float: right;
  width: 30px;
  height: 30px;
  background: url(../images/close.png) top right no-repeat;
  text-align: right;
  outline: none;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=70);
  opacity: 0.7;
  -webkit-transition: opacity 0.2s;
  -moz-transition: opacity 0.2s;
  -o-transition: opacity 0.2s;
  transition: opacity 0.2s;
}

.lb-data .lb-close:hover {
  cursor: pointer;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1;
}

.main-aside h3 {
  border-bottom: 1px solid #ccc;
}

.form-block h4 {
  margin-bottom: 3px;
}


/* MEDIA QUERIES
  ============================================================*/


@media screen and (min-width: 319px) {

  .hero {
    height: 600px
  }

}

/*====== END 319 Media Query=========*/

@media screen and (min-width: 370px) {

  header .logo-big {
    font-size: 36px;
  }

  .strapline {
    font-size: 30px;
  }

}



.property-listing-tabs li.active a {
  background-color: black;
  color: white;
}

.property-listing-tabs li.active a:hover {
  background-color: black;
  color: white;
}

.property-listing-tabs li {
  a {
    background-color: rgb(207, 48, 32);
    border-radius: 0;
    margin-right: 0;
    color: white;

  }

}

.property-listing-tabs .nav-tabs li {
  white-space: nowrap;
  text-align: center;
}

.property-listing-tabs li a:hover {
  background-color: rgb(207, 48, 32);
  color: white;
  border-color: transparent;
}

@media screen and (min-width: 400px) {
  .property-listing-tabs .nav-tabs li {
    width: 25% !important;
  }

  .property-listing-tabs li a {
    border-radius: 20px 20px 0 0;
    margin-right: 2px;
  }

  .property-listing-tabs li.active a {
    border: 1px solid transparent;
  }

  .property-listing-tabs li.active a:hover {
    border-color: transparent;
  }
}

@media screen and (min-width: 486px) {
  .main-prop-data {
    background: #eee;
    margin-right: 10px;
  }

  .main-prop-data img {
    max-width: 250px;
  }
}

@media screen and (min-width: 540px) {

  .cta-buttons {
    width: 500px;
  }

  .cta-buttons .btn {
    width: auto;
    margin-bottom: 0;
  }

  .listing-container-bottom img {
    max-width: 100%;
    height: auto;
    padding-bottom: 5px;
    width: 32%;
    max-height: 10000px;
  }


}

/*====== END 500 Media Query=========*/

@media screen and (min-width: 600px) {

  .front-listing-top .property-price {
    display: block;
  }

}

/*====== END 600 Media Query=========*/

@media screen and (min-width: 620px) {
  header nav .menu {
    width: 600px;
    margin-left: auto;
    margin-right: auto;
    display: inline-block;
  }

  header nav .menu li {
    width: auto;
  }

  header nav .menu li a {
    width: auto;
  }

  .property-amenities.group li {
    width: 50% !important;
    float: left;
  }
}

@media screen and (min-width: 700px) {}

/*====== END 700 Media Query=========*/

/*======= -sm (768 and higher) ====== */
@media screen and (min-width: 768px) {

  header nav .menu {
    width: auto;
  }

  header {

    nav .top-nav,
    .primary-nav .nav {
      float: right;
    }
  }

  .top-nav {
    ul {
      display: inline-block;
    }
  }

  header.open {
    height: auto;
  }

  nav .nav li {
    float: left;
  }

  header nav .nav li a:after,
  footer nav .menu li a:after {
    content: "|";
    position: relative;
    left: 15px;
  }

  footer nav .nav li a:after {
    content: "|";
    position: relative;
    left: 10px;
  }

  header .logo-small,
  footer .logo-small {
    font-size: 18px;
  }

  .property-list-heading .property-price {
    color: white;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 20px;
    display: block;
    background: black;
    float: right;
    border-radius: 0 10px 0 0;
  }

  .property-list-heading .property-address {
    display: block;
    padding-right: 10px;
    font-size: 15px;
    padding-left: 20px;
    float: left;
    padding-top: 8px;
  }

  .property-list-heading .property-title {
    padding-top: 5px;
    display: block;
    padding-left: 10px;
    font-size: 18px;
    float: left;
  }

}

/*====== END 768 Media Query=========*/

/*======= -md (992 and higher) ====== */
@media screen and (min-width: 992px) {
  header nav {
    display: block;
  }

  .main-aside {
    float: left;
  }

  .interior-content,
  .theContent,
  .listing-content {
    float: right;
  }

  .frontBlog article {
    float: left;
    width: 33.333333333%;
    padding: 0 15px;
  }

  .linksCopy {
    float: left;
  }

  .clientCopy {
    float: right;
  }

  .hero {
    height: 500px;
  }

  header nav .real-nav {
    padding-top: 0;
  }

  header nav .nav li a,
  footer nav .nav li a {
    padding: 10px 9px;
  }

  footer nav .top-nav {
    text-align: right;
  }

  header nav .nav li a:after {
    left: 9px;
  }

  .listing-container-bottom img {
    max-width: 100%;
    height: auto;
    padding-bottom: 5px;
    width: 24%;
    max-height: 10000px;
  }

}

/*====== END 992 Media Query=========*/

/*======== -lg (1200 and higher) =====*/
@media screen and (min-width: 1200px) {

  header nav .nav li a,
  footer nav .nav li a {
    padding: 10px 5px;
    font-size: 16px;
  }

  header nav .nav li a:after,
  footer nav .menu li a:after {
    left: 15px;
  }

  footer nav .nav li a:after {
    left: 5px;
  }

  header nav .nav li a:after {
    left: 5px;
  }

  .property-amenities.group li {
    width: 33% !important;
    float: left;
  }

}

/*====== END 1200 Media Query========*/

#property-img-carousel .carousel-indicators {
  margin: 10px 0 0;
  overflow: auto;
  position: static;
  text-align: left;
  white-space: nowrap;
  width: 100%;
}

#property-img-carousel .carousel-indicators li {
  background-color: transparent;
  -webkit-border-radius: 0;
  border-radius: 0;
  display: inline-block;
  height: auto;
  margin: 0 !important;
  width: auto;
}

#property-img-carousel .carousel-indicators li img {
  display: block;
  opacity: 0.5;
  max-width: 66px;
  max-height: 50px;
}

#property-img-carousel .carousel-indicators li.active img {
  opacity: 1;
}

#property-img-carousel .carousel-indicators li:hover img {
  opacity: 0.75;
}

#property-img-carousel .carousel-outer {
  position: relative;
}

.property-img {
  height: 340px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.entry-content .property-amenities li {
  list-style-type: disc;
}

.pagination {
  display: block;
  width: 100%;

  .navigation {
    display: inline-block;
    width: inherit;

    .nav-links {
      display: inherit;
      width: inherit;
      text-align: center;

      .page-numbers {
        display: inline-block;
        padding: 10px 15px;
        float: unset;
        color: #333;
        font-weight: bold;

        &:hover,
        &:focus,
        &:active {
          text-decoration: none;
        }
      }

      .current {
        color: #bbb;
      }

      .prev,
      .next {
        padding: 10px 15px;
        border: 1px solid #cf3020;
        background-color: #cf3020;
        color: #fff;
        font-weight: normal;
        transition: all 0.25s ease-in-out;
      }

      .prev {
        margin-left: 15px;
        float: left;
      }

      .next {
        margin-right: 15px;
        float: right;
      }
    }
  }
}

.property-buttons {
  display: flex;
  flex: 0 0 100%;
  max-width: 100%;
  flex-wrap: wrap;

  .property-button {
    flex: 0 0 100%;
    max-width: 100%;
    padding: 5px;

    @media (min-width: 450px) and (max-width: 991px) {
      flex: 0 0 50%;
      max-width: 50%;
    }

    @include laptop {
      flex: 0 0 100%;
      max-width: 100%;
    }

    @include desktop {
      flex: 0 0 50%;
      max-width: 50%;
    }

    .btn {
      margin: 0;
      width: 100%;
    }
  }
}

.request-showing {
  margin: 15px 0;

  .heading {
    padding: 5px 10px;
    color: #fff;
    background-color: #cf3020;
    border-radius: 10px 10px 0 0;
    font-size: 18px;
  }

  .gform_wrapper {
    border: 1px solid #ccc;
    padding: 10px;
    background-color: #fff;
    margin: 0;

    .gfield {
      margin-top: 10px;

      .ginput_container {
        margin: 0;

        textarea {
          height: 150px;
        }

        &.ginput_container_radio {
          margin: 0;
        }
      }

      .g-captcha {
        margin-top: 15px;

        label {
          display: none;
        }
      }
    }

    .gform_footer {
      margin: 0;
    }
  }
}

.video-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;

  iframe,
  object,
  embed,
  video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
