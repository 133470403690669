footer .nav.menu li, footer .nav li {
  width: 100%;
  text-align: center;
}

footer .logo {
  margin-bottom: 20px;
  @include laptop {
    margin-bottom: 0;
  }
}

footer {
  text-align: center;
  @include laptop {
    text-align: left;
    menu .top-nav, .primary-nav .nav {
      float: right;
    }
  }

  .primary-nav {
    ul.nav {
      display: inline-block;
    }
  }

  h1 {
    display: inline-block;
    text-align: left;
  }

  li.hide-footer {
    display: none!important;
  }
}

@include tablet {
  footer .nav.menu li, footer .nav li {
    width: auto;
    text-align: left;
  }

}
