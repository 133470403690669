.sideNav {
    width: $sideNavWidth;
    #{$sideNavDirection}: -$sideNavWidth;
    box-shadow: inset 9px 0 9px -9px rgba(0,0,0,1);
    position: fixed;
    z-index: 1000;
    height: 100%;
    top: 0;
    background: #333;
    transition: all 0.3s ease;
    @include tablet {
      width: $sideNavWidth + 100px;
      #{$sideNavDirection}: -$sideNavWidth - 100px;
    }

    .nav {
      li {
        float: none;


        a {
            display: block;
            text-transform: uppercase;
            color: #fff;
            padding: 12px 20px;
            @include tablet {
              padding: 14px 20px;
            }
        }

        ul {
          list-style-type: none;
          padding-left: 15px;
        }

        &.mobile-phone {
          a {
            position: relative;
            &:before {
              font-family: FontAwesome;
              content: '\f095';
              margin-right: 7px;
            }
          }
        }
      }
    }



    &.sideNav-open {
        #{$sideNavDirection}: 0px;
    }
}

/* classes applied to the body */
.sideNavBody {
    overflow-x: hidden;
    position: relative;
    #{$sideNavDirection}: 0;
    transition: all 0.3s ease;
}

.sideNavBody-push {
    #{$sideNavDirection}: $sideNavWidth;
    @include tablet {
      #{$sideNavDirection}: $sideNavWidth + 100px;
    }
}
