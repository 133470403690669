.login_form * {
  margin: 10px 0 10px;
}

button {
  background: #cf3020;
  color: #fff;
  outline: none;
  border: none;
  padding: 5px 10px 5px 10px;
}

#user_terms_register_wd_label {
  margin: 5px;
}

.login-widget li {
  list-style: none;
}

.top-nav {
  .nav {
    > li {
      > a {
        padding: 5px 8px;
        &:after {
          left: 8px;
        }
      }
    }

    &.real-nav {
    @include tablet {
      margin-right: -4px;
        li:last-of-type {
          a {
            padding: 5px 8px;
            &:after {
              content: '';
            }
          }
        }
      }
    }
  }
}

.primary-nav {
  .nav {
    > li {
      > a {
        padding: 8px 15px;
        &:after {
          left: 15px;
        }

        @include tablet {
          font-size: 20px;
        }
      }
    }
  }
}
