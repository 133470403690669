/** GRAVITY FORMS STYLES **/

.g-form-group {
  display: inline-block;
  vertical-align: middle;
}

.g-input-100 {
  clear:both;
  width: 100%!important;
}

.g-input-60 {
  clear:both;
  width: 60%!important;
}

.g-input-50 {
  clear:both;
  width: 50%!important;
}

.g-input-40 {
  clear:both;
  width: 40%!important;
}

.g-input-30 {
  clear:both;
  width: 30%!important;
}

.g-input-25 {
  clear:both;
  width: 25%!important;
}

.g-input-20 {
  clear:both;
  width: 20%!important;
}

.g-input-16 {
  clear:both;
  width: 16%!important;
}

.g-input-custom input {
  width: 100%!important;
  vertical-align: middle;
}

.g-input-custom .gfield_label {
  width: 100%!important;
  vertical-align: middle;
  height: 50px;
  padding: 5px;
  word-wrap: break-word;
}

.g-input-custom.g-no-label .gfield_label {
  width: 100%!important;
  vertical-align: middle;
  height: 0px;
  padding: 0;
  margin-top: 0;
}

.g-input-custom input.datepicker {
  width: 100%!important;
  vertical-align: middle;
}

.ginput_container .instruction {
  display: none;
}

.gsection.gfield {
  border: none;
  margin-bottom: 0.3em;
}

.gsection.gfield h2 {
  color: black;
  font-size: 24px;
}

.gsection.gfield.g-small-section {
  border: none;
  margin-top: 1em;
  margin-bottom: 0.3em;
}

.gsection.gfield.g-small-section h2 {
  color: black;
  font-size: 18px;
}

.gsection.gfield.g-paragraph-section {
  border: none;
  margin-top: 1em;
  margin-bottom: 0.3em;
}

.gsection.gfield.g-paragraph-section h2 {
  color: black;
  font-size: 16px;
  font-weight: 300;
  line-height: 22px;
}

input[type=submit] {
  font-size: 20px;
  background: #cf3020;
  color: #fff;
  transition: background .3s ease-in;
  border-radius: 0;
  outline: none;
  border: none;
  padding:10px;
}

input[type=submit]:hover {
	color: #fff;
	background: #e14f41;
}

/** video styles **/

.main-media-container iframe {
  width: 275px;
  height: 240.75px;
}

.main-media-container{
  padding-bottom: 15px;
}

.video {
	position: relative;
	padding-bottom: 56.25%; /* 16:9 */
	padding-top: 25px;
	height: 0;
}
.video > iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.small-vid i {
  font-size: 1025%;
  color:#cf3020;
}

.followed-properties {
  color: black;
  padding-bottom: 0px;
  font-size: 25px;
  font-weight: 300;
}

/** Agent page styles **/

.mylistings {
  margin-top:20px;
}

.agent_meta {
  float:right;
  padding-top: 5px;
  padding-right: 10px;
  display: none;
}

.agent_meta > a {
  color:white;
  cursor: pointer;
}

.agent_details {
  padding-top: 10px;
  float: none;
  display: inline-block;
  vertical-align: middle;
}

.agent_detail i {
  padding-right: 5px;
}

.agent_content {
  padding-top: 10px;
  float: none;
}

.single-content {
  padding-top: 15px;
}

.agentpic-wrapper {
  float: none;
  display: inline-block;
  vertical-align: middle;
}

/** front page styles **/

.strap p {
  font-size: 25px;
}

/** properties page **/


/*=Google Maps
-------------------------------------------------------------------------------------*/
#gmap_wrapper{
    position:relative;
    transition: height 0.3s ease;
    margin-bottom: 30px;
}

#googleMap{
    width:100%;
    transition: height 0.3s ease;
}

#google_map_prop_list_wrapper{
    position: fixed;
    left: 0;
    bottom: 0;
    width: 50%;
    top: 130px;
    background-color: #fff;
}

#google_map_prop_list_wrapper.fullscreen{
    width: 100%;
    top: 0px;
    height:100%;
    z-index: 999;
}

#google_map_prop_list_sidebar.fullscreen{
    display:none;
}


#google_map_prop_list_wrapper.fullhalf{
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    top: 0px;
    height: 100%;
}


#google_map_prop_list_wrapper #gmap_wrapper,
#google_map_prop_list_wrapper #googleMap{
    height:100%!important;
}

.admin-bar #google_map_prop_list_wrapper,
.admin-bar #google_map_prop_list_sidebar{
    margin-top:32px;
}


#google_map_prop_list_sidebar.half_type2,
#google_map_prop_list_sidebar.half_type3,
#google_map_prop_list_wrapper.half_type2,
#google_map_prop_list_wrapper.half_type3{
    margin-top:27px;
}


.admin-bar #google_map_prop_list_sidebar.half_type3,
.admin-bar #google_map_prop_list_sidebar.half_type2,
.admin-bar #google_map_prop_list_wrapper.half_type2,
.admin-bar #google_map_prop_list_wrapper.half_type3{
    margin-top:70px;
}

#google_map_prop_list_wrapper.half_no_top_bar.half_type2,
#google_map_prop_list_sidebar.half_no_top_bar.half_type2,
#google_map_prop_list_wrapper.half_no_top_bar.half_type3,
#google_map_prop_list_sidebar.half_no_top_bar.half_type3 {
    top: 88px;
}


.google_map_shortcode_wrapper{
    position:relative;
}

#googleMapSlider{
    position: absolute;
    width: 100%;
    height: 100%;
    float: left;
    display: block;
    z-index: 1;
    display: none;
}

#googleMap_shortcode{
    width: 100%;
    height: 100%;
    min-height: 400px;
    background: #F0F0F0;
}


.scrollon{
    border:1px solid #3C90BE;
}

.fullmap{
    position: fixed!important;
    width: 100%;
    height: 100%;
    margin: 0px;
    padding: 0px;
    top: 0px;
    lefT: 0px;
    z-index: 98;
}

.header_full_map{
    z-index:90;
}

#openmap{
    background-color: #99a3b1;
    color: #fff;
    position: absolute;
    top: 100%;
    right: 0;
    /* margin-right: -17px; */
    text-align: center;
    padding: 8px 10px 8px 10px;
    font-size: 13px;
    cursor: pointer;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    z-index: 99;
    pointer-events: auto;
}

.page-template-advanced_search_results .with_search_2 #openmap{
    bottom: 0px;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
}

#google_map_prop_list_wrapper #openmap{
    display:none;
}

#openmap i{
    margin-right: 5px;
}


#gmap-loading{
    background-color: #fff;
    color: #434a54;
    margin: 0px auto;
    position: absolute;
    top: 20%;
    left: 50%;
    width: 300px;
    height: 105px;
    margin-left: -150px;
    text-align: center;
    padding-top: 16px;
    font-size: 27px;
    font-weight: 300;
    border-radius: 4px;
    opacity: 0.95;
}

#gmap-noresult{
    background-color: #fff;
    color: #434a54;
    margin: 0px auto;
    position: absolute;
    top: 20%;
    left: 50%;
    width: 280px;
    height: 78px;
    margin-left: -150px;
    text-align: center;
    padding-top: 30px;
    font-size: 20px;
    font-weight: 300;
    border-radius: 4px;
    opacity: 0.95;
    display: none;
    z-index: 101;
}

.gmap-controls{
    margin: 0px auto;
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    pointer-events:none;
}

#google_map_prop_list_wrapper .gmap-controls{
    top: 0px;
    left: 0px;
    margin-left: 0px;
}
#google_map_prop_list_wrapper #gmap-control{
    right:15px;
}

.fullmap .gmap-controls{
    max-width: 100%;
    width:100%;
    left:0px;
    margin-left: 0px;
}

#street-view{
    position: absolute;
    top: 60px;
    right: 45px;
    background-color: #99a3b1;
    color: #fff;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    padding: 9px 11px 0px 11px;
    border-radius: 4px;
    font-size: 13px;
    float: left;
    cursor: pointer;
    height: 40px;
    width: 119px;
    z-index:99;
    pointer-events: auto;
}

#street-view:hover{
    background-color:#434a54;
}
#street-view i{
    font-size: 15px;
    padding: 3px 5px 0px 0px;
}


#gmap-control{
    text-align: center;
    position:absolute;
    bottom:30px;
    right:15px;
    left: 15px;
    background-color: #99a3b1;
    border-radius: 4px;
    opacity:0.95;
    max-width: calc(100% - 30px);
    pointer-events: auto;
    @include phablet {
      bottom: auto;
      left: auto;
      top:15px;
    }
}

.fullmap #gmap-control{
    z-index:111;
}

#gmap-control i{
    margin-right: 7px;
    margin-left: 0px;
}

#gmap-control .fa-square-o{

}

#gmap-next i{
    margin-right: 0px;
    margin-left: 7px;
}


#gmap-control span{
    color: #fff;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    padding: 12px 11px;
    font-size: 13px;
    cursor: pointer;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
    &:not(.map-type) {
      display: inline-block;
    }
    &#map-view, &#gmap-full {
      display: none;
      @include tablet {
        display: inline-block;
      }
    }

}

#gmap-control span.spanselected,
#gmap-control span:hover{
    background-color:  #434a54;
}
.gmnoprint img { max-width: none!important; }

#googleMap_shortcode img,
#googleMapSlider img {
    border: none !important;
    max-width: none !important;
}

.mapcontrolon{
    background-color:#434a54!important;
    width:150px!important;
    z-index:99;
}

#gmap-next{
    border-right:none!important;
}
#gmapzoomplus_sh,
#gmapzoomplus{
    background-color: #99a3b1;
    color: #fff;
    font-size: 16px;
    position: absolute;
    top: 15px;
    left: 15px;
    width: 30px;
    height: 30px;
    cursor: pointer;
    font-weight: 300;
    padding: 5px 0px 0px 9px;
    box-sizing: border-box;
    z-index: 99;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
    pointer-events: auto;
}
#gmapzoomminus_sh,
#gmapzoomminus{
    background-color: #99a3b1;
    color: #fff;
    font-size: 14px;
    position: absolute;
    top: 46px;
    left: 15px;
    width: 30px;
    height: 30px;
    cursor: pointer;
    font-weight: 300;
    padding: 5px 0px 0px 9px;
    box-sizing: border-box;
    z-index: 99;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
    pointer-events:auto;
}

#google_map_prop_list_wrapper #gmapzoomminus{
    left: 15px;
}

#google_map_prop_list_wrapper #gmapzoomplus{
    left: 15px;
}
#gmapzoomplus_sh:hover,
#gmapzoomminus_sh:hover,
#gmapzoomplus:hover,
#gmapzoomminus:hover{
    background-color: #434a54;
    color: #fff;
}



#gmapzoomminus.smallslidecontrol{
    left:15px;
    top:96px;
    padding-top:5px;
    display:none;
}

#gmapzoomplus.smallslidecontrol{
    top:63px;
    left:15px;
    padding-top:5px;
    display:none;
}

#gmapzoomplus_sh.shortcode_control{
    left:15px;
    padding-top:5px;
    display:block;
    top:15px;
}

#gmapzoomminus_sh.shortcode_control{
    left:15px;
    padding-top:5px;
    top:47px;
    display:block;
}

#slider_enable_street_sh{
    background-color: #99a3b1;
    color: #fff;
    font-size: 14px;
    position: absolute;
    top: 46px;
    left: 15px;
    width: 30px;
    height: 30px;
    cursor: pointer;
    font-weight: 300;
    padding: 4px 0px 0px 9px;
    box-sizing: border-box;
    z-index: 99;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
    pointer-events: auto;
    top: 79px;
    display: block;
}



#google-default-search{
    top: 0px!important;
    width: 300px;
    position: absolute;
    right: 770px;
}

#google_map_prop_list_wrapper #google-default-search {
    top: 0px!important;
    width: 240px;
    position: absolute;
    right: 420px;
}



.map-type{
    position:absolute;
    bottom:40px;
    left:0px;
    background-color: #99a3b1;
    width:80px;
    height: 40px;
    display:none;
    @include phablet {
      top:40px;
    }
}

#map-view-satellite{
    bottom:80px;
    @include phablet {
      top:80px;
    }
}

#map-view-hybrid{
    bottom:120px;
    @include phablet {
      top:120px;
    }
}

#map-view-terrain{
    bottom:160px;
    @include phablet {
      top:160px;
    }
}

/** media queries **/

@include mobile {
  .g-input-custom .gfield_label {
    height: 35px;
  }

}
@include tablet {
  .agent_meta {
    display: block;
  }
}
@include laptop {

}
@include desktop {
  .g-input-custom .gfield_label {
    height: 20px;
  }
}

.lslide {
  position: relative;
  cursor: pointer;


  .caption {
    background-color: rgba(0, 0, 0, 0.50);
    height: 60px;
    position: absolute;
    bottom: 0px;
    left: 0;
    padding: 20px;
    color: white;
    width: 100%;
  }

  .caption p {
    margin: 0;
  }

  &:before {
    background-color: rgba(0,0,0,0.2);
    position: absolute;
    transition: 0.4s ease-in-out;
    z-index: 99;
    content: '';
    opacity: 0.8;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    //@media screen and (min-device-width: 992px) {
    //  opacity: 0;
    //}
  }

  &:after {
    content: '\f00e';
    font-family: FontAwesome;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 60px;
    transform: translate(-50%, -50%);
    z-index: 100;
    font-size: 40px;
    color: #fff;
    opacity: 0.8;
    transition: 0.4s ease-in-out;
    //@media screen and (min-device-width: 992px) {
    //  opacity: 0;
    //}
  }

  &:hover, &:focus {
    &:before {
      opacity: 1;
    }
    &:after {
      opacity: 0.8;
    }
  }

  &.video-slide {
    &:after {
      content: '\f16a';
    }
  }

}

.mainContent {
    ul {
        margin: 0 0 0 20px;
    }
}

// .ad-banner {
//   img {
//     width: 100%;
//     display: block;
//     margin: 20px auto;
//
//     @include laptop {
//       width: 700px;
//     }
//   }
//
// }

.wild-fox {
  margin-bottom:20px;
}
