.main-aside {
  display: block;
  padding: 20px 15px 0;
  margin: 0 auto 40px;
  text-align: center;

  .real-estate-site a img, .application-buttons a img {
    display: block;
    margin: 0 auto;
    margin-bottom: 10px;
  }

  .btn {
    display: block;
    font-size: 24px;
    margin: 0 auto;
    max-width: 263px;
  }

}

aside .promo {
    padding-bottom: 15px;
}

aside .promo:last-of-type{
  padding-bottom: 0px;
}

aside .application-buttons img {
    padding-bottom: 15px;
}


@include laptop {

  .main-aside-container {
    width:25%;
  }

  .main-aside {
    display: block;
    margin: 20px -15px;
    text-align: left;

    .real-estate-site a img, .application-buttons a img {
      display: block;
      margin: auto;
      margin-bottom: 0;
    }

  }

}
